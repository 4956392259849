<script setup lang='ts'>
import type { DefineComponent } from 'vue'
import { isValidKey, numFormat, timeFrom } from '@qctsw/utils'
import { APP_ROUTER } from '@qctsw/common'
import { useContentOperation } from '@/composables/useContentOperation'
import { NuxtLink } from '#components'
import type { CommonType } from '@/server/types'

const props = withDefaults(defineProps<{
  tipsData?: Record<string, number | string>
  size?: string | number
  dataId?: number
  dataType?: CommonType
  canSetGood?: boolean
  isGood?: boolean
  isCollect?: boolean
  authorId?: number
}>(), {
  tipsData: () => ({}),
  size: '12',
  dataId: -1,
  dataType: 'complaint',
  canSetGood: false, // 能否设置点赞
  isGood: false,
  isCollect: false,
  authorId: 0,
})

const { setFavor, setCollection } = useContentOperation()
// 日期
const commonTipsComponent = defineComponent((props: Tips) => {
  // console.log('🚀 ~ commonTipsComponent ~ props:', props)
  return props.data
    ? () => h('span', { class: 'pr-sm whitespace-nowrap', title: props.data }, { default: () => timeFrom(props.data) })
    : () => h('span', { class: 'whitespace-nowrap' }, { default: () => props.data })
})
const authorTipsComponent = defineComponent((props: Tips) => {
  return () => h(NuxtLink, { to: APP_ROUTER.authorDetail(props.authorId || ''), class: 'pr-4 whitespace-nowrap hover:text-orange-6' }, { default: () => (!props.data && props.authorId) ? '暂无笔名' : props.data })
})
const lookTipsComponent = defineComponent((props: Tips) => {
  return () => h('span', { class: 'pr-4 whitespace-nowrap' }, [
    h('i', { class: 'mr-0.75 i-carbon:view text-1.1em vertical-icon' }),
    h('span', numFormat(props.data as number)),
  ])
})
const commentTipsComponent = defineComponent((props: Tips) => {
  return () => h('span', {
    class: props.data ? 'pr-4 whitespace-nowrap cursor-pointer' : '',
  }, props.data
    ? [
        h('i', { class: 'i-uil:comment-dots mr-0.75 text-1.1em vertical-icon' }),
        h('span', numFormat(props.data as number)),
      ]
    : [])
})
// setFavor(props.dataType ?? '', props.dataId ?? -1, !props.isGood)
const favorTipsComponent = defineComponent((props: Tips) => {
  return () => h('span', {
    class: (props.canSetGood || props.data) ? 'pr-4 whitespace-nowrap cursor-pointer' : '',
    onClick: () => props.canSetGood
      ? setFavor(props.dataType ?? 'news', props.dataId ?? -1, !props.isGood)
      : '',
  }, (props.canSetGood || props.data)
    ? [
        props.isGood
          ? h('i', { class: 'mr-0.75 i-bxs:like text-blue-5 vertical-icon text-1.1em' })
          : h('i', { class: 'mr-0.75 i-bx:like text-1.1em vertical-icon' }),
        h('span', numFormat(props.data as number)),
      ]
    : [])
})
// cooperation
const cooperationTipsComponent = defineComponent((props: Tips) => {
  return () => h('span', { class: 'pr-4 whitespace-nowrap' }, [
    h('i', { class: 'i-pepicons-pencil:handshake mr-0.75 text-1.1em' }),
    h('span', props.data),
  ])
})

const collectionComponent = defineComponent((props: Tips) => {
  return () => h('span', {
    class: 'pr-6 cursor-pointer whitespace-nowrap',
    onClick: () => setCollection(props.dataType ?? 'news', props.dataId ?? -1, !props.isCollect),
  }, [
    props.isCollect
      ? h('i', { class: 'mr-0.75 i-tabler:star-filled text-blue-5 text-1.1em vertical-icon' })
      : h('i', { class: 'mr-0.75 i-tabler:star text-1.1em vertical-icon' }),
    h('span', '收藏'),
  ])
})

interface Tips {
  type?: string
  data?: number | string
  isGood?: boolean
  isCollect?: boolean
  dataId?: number
  dataType?: CommonType
  canSetGood?: boolean
  authorId?: number
}
interface TipList {
  component: DefineComponent
  key: string
}
const tipsType = {
  date: commonTipsComponent,
  author: authorTipsComponent,
  commentCount: commentTipsComponent,
  lookCount: lookTipsComponent,
  favorCount: favorTipsComponent,
  cooperationCount: cooperationTipsComponent,
  collection: collectionComponent,
} as const
const tipsList: TipList[] = []
for (const key in props.tipsData) {
  if (!isValidKey(key, tipsType))
    continue
  // 声明props
  // eslint-disable-next-line ts/ban-ts-comment
  // @ts-expect-error
  tipsType[key].props = ['data', 'isGood', 'isCollect', 'dataId', 'dataType', 'canSetGood', 'authorId']
  tipsList.push({
    component: tipsType[key],
    key,
  })
}
</script>

<template>
  <span class="inline-flex items-center text-gray-5" :style="{ fontSize: `${size}px` }">
    <template v-for="(v, i) in tipsList" :key="i">
      <component
        :is="v.component"
        :data="tipsData[v.key]"
        :author-id="props.authorId"
        :is-good="props.isGood"
        :is-collect="props.isCollect"
        :data-id="props.dataId"
        :data-type="props.dataType"
        :can-set-good="props.canSetGood"
      />
    </template>
  </span>
</template>
