import { postCommonCollectApi, postCommonFavoriteApi } from '@/server'
import type { CommonType } from '~/server/types'
import { COMMON_CHANGE_FAVORITE_OR_COLLECT_KEY } from '~/shared/event'

export function useContentOperation() {
  const authStore = useAuth()
  const { IS_LOGIN } = storeToRefs(authStore)

  const checkLogin = () => {
    if (!IS_LOGIN.value) {
      useMessage.warning('请先登录')
      useLoginPopupEvent().showLoginPopup('phone')
      return false
    }
    return true
  }
  const setFavor = async (type: CommonType, id: number, isFavorite: boolean) => {
    if (!checkLogin())
      return
    const text = isFavorite ? '点赞' : '取消点赞'

    if (!id || id === -1 || !type) {
      useMessage.error(`${text}失败, 请刷新后重试`)
      return
    }

    try {
      await postCommonFavoriteApi(type, { id, isGood: isFavorite })
      useMessage.success(`${text}成功`, { closable: true })
      useCommonFavoriteOrCollectEvent().emit({ contentType: type, id, type: 'favorite' })
    }
    catch (err) {
      const msg = getErrorMsg(err)
      useMessage.error(`操作失败，${msg || '操作失败，请稍后重试'}`)
      throw err
    }
  }

  const setCollection = async (type: CommonType, id: number, isCollect: boolean) => {
    if (!checkLogin())
      return

    const text = isCollect ? '收藏' : '取消收藏'
    try {
      await postCommonCollectApi(type, { id, isCollect })
      useMessage.success(`${text}成功`, { closable: true })
      useCommonFavoriteOrCollectEvent().emit({ contentType: type, id, type: 'collect' })
    }
    catch (err) {
      const msg = getErrorMsg(err)
      useMessage.error(`操作失败，${msg || '操作失败，请稍后重试'}`)
      throw err
    }
  }

  return {
    setFavor: useDebounceFn(setFavor, 200),
    setCollection: useDebounceFn(setCollection, 200),
  }
}

export function useCommonFavoriteOrCollectEvent() {
  const bus = useEventBus<{
    contentType: CommonType
    id: number
    type: 'favorite' | 'collect'
  }>(COMMON_CHANGE_FAVORITE_OR_COLLECT_KEY)

  return {
    bus,
    on: bus.on,
    emit: bus.emit,
    reset: bus.reset,
  }
}
